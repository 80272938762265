import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { isInIsrael } from '../utils/geolocation';

const PageContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin: 4rem 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  svg {
    font-size: 1.5rem;
    color: #8A2BE2;
    margin-top: 0.2rem;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const SectionTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const ContactForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 6px;
  background: ${props => props.theme.colors.backgroundLight};
  color: ${props => props.theme.colors.text};
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

const TextArea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 6px;
  background: ${props => props.theme.colors.backgroundLight};
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 6px;
  background: ${props => props.theme.colors.backgroundLight};
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

const ContactButton = styled(motion.button)`
  background: linear-gradient(135deg, #00ffff 0%, #8A2BE2 100%);
  color: #000000;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    color: #000000;
  }
`;

const ContactPage: React.FC = () => {
  const [showIsraelOption, setShowIsraelOption] = useState(false);

  useEffect(() => {
    const checkLocation = async () => {
      const inIsrael = await isInIsrael();
      setShowIsraelOption(inIsrael);
    };
    checkLocation();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData as any).toString(),
    })
      .then(() => {
        form.reset();
        alert('Thank you for your message. We will get back to you soon!');
      })
      .catch((error) => {
        console.error(error);
        alert('Oops! There was a problem submitting your form');
      });
  };

  return (
    <>
      <SEO
        title="Contact Our Web Design Team"
        description="Get in touch with our web design and development team. We're here to discuss your project needs and provide expert digital solutions for your business."
        keywords="contact web designer, hire web developer, web design consultation, digital agency contact, website project inquiry, free consultation"
        url="/contact"
        type="contact"
      />
      <PageContainer>
        <PageHeader
          title="Contact Us"
          subtitle="Let's discuss your next digital project"
        />
        <Container>
          <ContactGrid>
            <ContactForm
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/success"
              onSubmit={handleSubmit}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don't fill this out if you're human: <input name="bot-field" />
                </label>
              </p>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Input type="text" id="name" name="name" required />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Input type="email" id="email" name="email" required />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="location">Preferred Location</Label>
                <Select name="location" id="location" required>
                  <option value="">Select a location</option>
                  <option value="london">London, United Kingdom</option>
                  <option value="melbourne">Melbourne, Australia</option>
                  {showIsraelOption && (
                    <option value="yavne">Yavne, Israel</option>
                  )}
                </Select>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="subject">Subject</Label>
                <Input type="text" id="subject" name="subject" required />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="message">Message</Label>
                <TextArea id="message" name="message" required />
              </FormGroup>
              <ContactButton
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Send Message
              </ContactButton>
            </ContactForm>

            <ContactInfo>
              <SectionTitle>Get in Touch</SectionTitle>
              <ContactItem>
                <FaEnvelope />
                <div>
                  <h3>Email Us</h3>
                  <p>hello@neonpixeldesigns.com</p>
                </div>
              </ContactItem>
              <ContactItem>
                <FaMapMarkerAlt />
                <div>
                  <h3>Our Locations</h3>
                  <p>London, United Kingdom</p>
                  <p>Melbourne, Australia</p>
                  {showIsraelOption && (
                    <p>Yavne, Israel</p>
                  )}
                </div>
              </ContactItem>
            </ContactInfo>
          </ContactGrid>
        </Container>
      </PageContainer>
    </>
  );
};

export default ContactPage;
