interface GeolocationResponse {
  country_code: string;
}

let cachedCountryCode: string | null = null;

export const getUserCountry = async (): Promise<string> => {
  if (cachedCountryCode !== null) {
    return cachedCountryCode;
  }

  try {
    const response = await fetch('https://ipapi.co/json/');
    const data: GeolocationResponse = await response.json();
    cachedCountryCode = data.country_code;
    return data.country_code;
  } catch (error) {
    console.error('Error fetching location:', error);
    return '';
  }
};

export const isInIsrael = async (): Promise<boolean> => {
  const countryCode = await getUserCountry();
  return countryCode === 'IL';
};
